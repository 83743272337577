import React, {Component} from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import About__header from "../images/about_main.jpg"
import About__middle from "../images/cb_journey.jpg"
import Magento_logo from "../images/magento_logo.png"
import Shopify_logo from "../images/shopify_logo.png"
import Team__pic from "../images/team.jpg"
import Culture_pic from "../images/culture.jpg"
import Culture_abt from "../images/cb/culture.jpg"
import { LazyLoadImage } from 'react-lazy-load-image-component'

import "animate.css/animate.min.css"


export default class AboutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: ''
    }
  }

  componentDidMount() {
    this.setState({
      url: document.location.href
    });
  }
  render() {
    return (
        <Layout>
          <Helmet>
            <title>
            About CueForGood & Our Culture </title>
            <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
            <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"/>
            <link rel="canonical" href={this.state.url}/>
            <meta name="description"
                  content="Rooted in Chandigarh, India, CueForGood is a Shopify partner & Magento 2 trained agency aiming to empower purpose-driven online retailers through web design, development, and marketing."/>
            <meta property="og:site_name" content="CueForGood"/>
            <meta property="fb:app_id" content="289086684439915"/>
            <meta property="og:url" content={this.state.url}/>
            <meta property="og:title"
                  content="About CueForGood & Our Culture"/>
            <meta property="og:description"
                  content="Rooted in Chandigarh, India, CueForGood is a Shopify partner & Magento 2 trained agency aiming to empower purpose-driven online retailers through web design, development, and marketing."/>
            <meta property="og:image" content="https://www.CueForGood.com/images/cb.jpg"/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:description"
                  content="Rooted in Chandigarh, India, CueForGood is a Shopify partner & Magento 2 trained agency aiming to empower purpose-driven online retailers through web design, development, and marketing."/>
            <meta name="twitter:title"
                  content="About CueForGood & Our Culture"/>
            <meta name="twitter:site" content="@CueForGood"/>
            <meta name="twitter:image" content="https://www.CueForGood.com/images/cb.jpg"/>
          </Helmet>

          <section className="about_sec_row ">
            <div className="container">
              <div className="about_sec_row_lt pd_right_40">
                <h1 className="heading_main">The Advent of CueForGood</h1>
                <div className="about_sec_row_lt_text">
                  <p>They say that the journey of a thousand miles begins with a small step. We took that first step in 2005 as CueBlocks with three new IBM machines and the ardent vision of our founders - Pancham, Sarthak, and Avneet. Their mission - to provide the best design, digital marketing, and coding services that meet a universal standard.</p><br/>
                  <p>We rolled up our sleeves, polished our skills and took the big leap into the E-commerce industry. From designing to coding, we did it all! Getting acquainted with a diverse clientele from around the world, we adapted skills and techniques that paved our path. </p>
                </div>
                <div className="about_sec_row_lt_img">
                  <img
                      src={About__header}
                      alt="About CueForGood"
                      className="img-responsive"
                  />
                </div>
                <div className="about_sec_row_lt_text mr_bt_0">
                  <p>
                  Nineteen years later, we've undergone an important transformation! Now known as CueForGood, we are dedicated to working with purpose-led and sustainable businesses. Operating across global time zones, our wide range of clients and partners helps us stay the course and grow with each endeavor we undertake.</p>
                </div>
              </div>
              <div className="about_sec_row_rt pd_left_40">
                <h2 className="heading_main">Our journey</h2>

                <div className="about_sec_row_rt_img">

                  <img
                      src={About__middle}
                      alt="our Journey"
                      className="img-responsive"
                  />

                </div>
              </div>
            </div>
          </section>

          {/*  Specialization */}

          <section className="spcltn_sec_row ">
            <div className="container">
              <div className="spcltn_sec_row_div">
                <h2 className="heading_main">Super specialization</h2>
                <div className="spcltn_sec_row_div_lt pd_right_40">
                  <p>
                  An open-source E-commerce platform, Magento provides online merchants, a flexible shopping cart system that offers powerful marketing, search engine optimization, and catalog-management tools. Since 2009, we have been involved in setting up Magento stores, building custom modules, migrating merchants to Magento, and contributing to the Magento community.
                  </p>
                  <div className="spcltn_sec_row_div_lt_img">

                    <img
                        src={Magento_logo}
                        alt="Magento logo"
                        className="img-responsive"
                    />

                  </div>
                </div>
                <div className="spcltn_sec_row_div_rt pd_left_40">
                  <p>
                  Shopify is a completely cloud-based E-commerce platform that lets you start, grow and manage a business. With Shopify, you can sell your products in multiple places including the web, mobile, social media, online marketplaces, brick & mortar locations, and pop-up shops. We have been partners with Shopify since 2016 and continue to love the endless possibilities they offer to this day.
                  </p>
                  <div className="spcltn_sec_row_div_rt_img">

                    <LazyLoadImage
                        src={Shopify_logo}
                        alt="Shopify logo"
                        className="img-responsive"
                    />

                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Business */}

          <section className="business_sec_row ">
            <div className="container">
              <div className="business_sec_row_div">
                <div className="business_sec_row_div_lt pd_right_40">
                <img
                  src={Culture_abt}
                  alt="Team CueForGood"
                  className="img-responsive"
                />
                
                </div>
                <div className="business_sec_row_div_rt ">
                <h2 className="heading_main">An Insight into our Foresight </h2>
                  <p>
                  Having such a diverse bunch of professionals, differences in opinions are inevitable. However, we do have some things that, all of us as a team, agree on, believe in, and abide by conscientiously. Here is what you can call the credo of CueForGood.
                  </p>
                  <ul>
                    <li>Celebrate every big and small milestone in life</li>
                    <li>
                      Develop a mental space that contemplates a broader perspective.{" "}
                    </li>
                    <li>Practice intellectual empathy.</li>
                    <li>Foster active social ties.</li>
                    <li>Integrity is a state of mind.</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          {/* Team */}

          <section className="team_sec_row ">
            <div className="container">
              <div className="team_sec_row_div">
                <div className="team_sec_row_div_lt pd_right_40">
                  <h2 className="heading_main">Meet The Cue Team</h2>
                  <p>
                  Each individual brings their own character and charm to the workplace, making it an amazing place to work. We owe it to our fabulous CueTeam to always keep the wheels churning around here. Come! Get to know them a bit more.
                  </p>
                  <div className="team_sec_row_div_lt_btn">
                    <Link to="/team/" className="read_btn">
                      Read More..
                    </Link>
                  </div>
                  <div className="team_sec_row_div_lt_img">

                    <img
                        src={Team__pic}
                        alt="Team CueForGood"
                        className="img-responsive"
                    />

                  </div>
                  <div className="team_sec_row_div_lt_career">
                    <h2 className="heading_main">Be Part of the CueTeam</h2>
                    <p>A member of the CueTeam is a brilliant, exuberant, and pragmatic individual who emanates good vibes. If you love the work that you do, are always brimming with ideas, and are sending out positive vibes, then we are looking for you. Come join the CueTeam!
                    </p>
                    <Link to="/career/" className="btn main_cta">
                      Apply
                    </Link>
                  </div>
                </div>
                <div className="team_sec_row_div_rt pd_left_40">
                  <h2 className="heading_main">Life @ CueForGood</h2>
                  <p>
                  Customers will never love a company until the employees love it first. And we truly love what we do! From giving quality work to unwinding with some much-needed game-time, we do it all here. We take pride in our work and create opportunities to do more amazing things every day. A workspace that is full of brilliant, technically sound, and humble people, we are welcoming of everything and everyone. And if you exude positive vibes, you get special brownie points.
                  </p>
                  <div className="team_sec_row_div_rt_btn">
                    <Link to="/career/" className="read_btn">
                      Read More..
                    </Link>
                  </div>
                  <div className="team_sec_row_div_rt_img">
                    <img
                        src={Culture_pic}
                        alt="Culture at CueForGood"
                        className="img-responsive"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
    )
  }
}